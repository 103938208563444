<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
      <div v-if="show_clientes && $route.name == 'add-document'" class="div-clientes">
        <b>Selecciona a los clientes para esta firma</b>
        <br>

        <ion-col style="display: flex; overflow-x: auto;white-space: nowrap;box-shadow: inherit;">
          <div v-for="cliente in clientes" :key="cliente" align="left">
            <p class="p-clientes">
              <!--
              <ion-checkbox slot="end" @update:modelValue="cliente.selected = $event" :modelValue="cliente.selected">
              </ion-checkbox>
              -->

              <input type="checkbox" @change="setClienteSign($event,cliente)" :checked="valueCliente(cliente)">
              &nbsp;&nbsp;{{cliente.name}}&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
        </ion-col>
      </div>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container"  style="margin-left: 24px;margin-right: 24px;">
        <div class="card">
          <br>
          <ion-row v-if="step == 1">
            <ion-col  sizeLg="3" sizeMd="4" sizeXs="12"></ion-col>
            <ion-col  sizeLg="6" sizeMd="4" sizeXs="12">
              
              <ion-label style="float: left;">
                Ingrese el nombre del documento
              </ion-label>
              <br>
              <ion-input type="text" id="user" v-model="nombre_documento"/>
              
                <input type="file" ref="documentos" @change="getFile()" id="documento" style="display: none;">

                <div v-if="nombre_file != ''">
                  <br>
                  <ion-label style="float: left;margin-left: 10px;">
                    {{nombre_file}} 
                  </ion-label>
                  <br><br>
                </div>
                
                <br>
                
                <!--
                <ion-title>Selecciones los clientes</ion-title>
                
                <br>
                
                <div v-for="cliente in clientes" :key="cliente" align="left" style="margin-left: 12px">
                  <p style="font-weight: bold;color: #000">
                    <ion-checkbox slot="end" @update:modelValue="cliente.selected = $event" :modelValue="cliente.selected">
                    </ion-checkbox> 
                    &nbsp;&nbsp;{{cliente.name}}
                  </p>
                  <br>
                </div>
                -->
              
                <br>
              
                <ion-button color="dark" @click="file">
                  Subir Documento
                </ion-button>

                <ion-button color="dark" @click="postDocumento">
                  Enviar
                </ion-button>

            </ion-col>
            <ion-col  sizeLg="4" sizeMd="4" sizeXs="12"></ion-col>
          </ion-row>

          <div v-if="step == 2">
            <br>
            <ion-title>Archivo subido exitosamente. </ion-title>
            <br>
            <ion-row>
              <ion-col>
                <ion-button color="dark" @click="add_signature">
                  Agregar firma
                </ion-button>
              </ion-col>
              <ion-col></ion-col>
              <ion-col>
                <ion-button color="dark" @click="save_signature_areas">
                  Enviar
                </ion-button>
              </ion-col>
            </ion-row>
            
            <br>
            
            <div style="display:flex;justify-content: center;">
              <div id="panel">
                <div style="position: relative;" v-for="(signature_area,key) in signature_areas" :key="signature_area">
                  <div v-show="signature_area.num_page == current_page" :id="'signature_area-'+key" class="div-draw" @click="move('signature_area-'+key,key);" :class="{'selected-div-draw' : key == current_key}">
                    <div class="pencilProfile" @click="delete_sign(key)">
                      x
                    </div>
                    <div :id="'signature_area-'+key+'header'" contenteditable="true" class="div-draw-header" @blur="get_name_sign($event,key)" v-on:dblclick="edit_name_sign('signature_area-'+key)">
                      Firma 
                    </div>
                  </div>
                </div>
                
                <img id="img-pdf" class="img-pdf-convert"  :src="baseURL+'/'+document_images[current_page]">
                
                <br><br>
                
                <div  style="display:flex;justify-content: center;">
                  <nav aria-label="page navigation example">
                    <ul class="pagination justify-content-center">
                      <li  :class="{'page-item' : true , 'disabled' : (current_page+1) == 1 }">
                        <a class="page-link" @click="current_page--" tabindex="-1">Anterior</a>
                      </li>
                      <li class="page-item"><a class="page-link">{{current_page+1}}</a></li>
                      <li :class="{'page-item' : true , 'disabled' : (current_page+1) >= document_images.length }">
                        <a class="page-link" @click="current_page++">Siguiente</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <br><br><br><br><br><br>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { 
  IonInput, 
  //IonCheckbox 
} from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'
import $ from 'jquery'

export default defineComponent({
  name: 'AgregarColaborador',
  components: { 
    IonInput, 
   // IonCheckbox 
  },
  setup() {
    return { close }
  },
  data(){
    return { 
      baseURL: axios.defaults.baseURL,
      nombre_documento: null,
      documento: null,
      nombre_file : '',
      clientes_selected : [],
      clientes :[],
      siniestro_id : null,
      step: 1,
      current_page: 0,
      signature_areas: [],
      current_div: null,
      current_key: null,
      show_clientes: false,
      document_id: null
    }
  },
  mounted(){
    this.siniestro_id = this.$route.params.siniestro_id
    this.getClienteBySiniestro()
    document.getElementById('container').onclick = this.showClientes
  },
  methods:{
    showClientes(e){
      if(e.target.id.split("-")[0] != "signature_area") {
        this.show_clientes = false
      }else{
        this.show_clientes = true
      }
    },
    valueCliente(cliente){
      var response = false

      var current_key = this.current_key
      
      this.signature_areas[current_key].user_ids.forEach(user_id => {
        if(user_id == cliente.id){
          response = true
        }
      })

      return response
    },
    setClienteSign($event,cliente){
      var current_key = this.current_key 

      if($event.target.checked){ 
        let exists = false;

        this.signature_areas[current_key].user_ids.forEach(user_id => {
          if(user_id == cliente.id){
            exists = true
          }
        })

        if (!exists) {
          this.signature_areas[current_key].user_ids.push(cliente.id)
        }

      }else{
        this.signature_areas.user_ids.forEach((user_id,key) => {
          if(user_id == cliente.id){
            this.signature_areas[current_key].user_ids.splice(key)
          }
        })
      }
    },
    file(){
      document.querySelector('#documento').click()
    },
    getFile(){
      this.documento = this.$refs.documentos.files[0]
      this.nombre_file = 'Nombre del Archivo: '+  this.documento.name
    },
    getClienteBySiniestro(){
      axios.get('/api/users/clientes/bySiniestro/'+this.siniestro_id)
      .then(res => {
        var clientes = res.data.data

        clientes.forEach(cliente => {
          cliente.selected = true
        });
        
        this.clientes = clientes
      })
      .catch(error => {
        console.log(error)
      })
    },
    async postDocumento(){
      
      if (this.nombre_documento == null) {
        this.$toast.openToast("El nombre del documento es requerido","success")
        return;
      }
      
      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData();
      
      formData.append('siniestro_id',this.siniestro_id)
      formData.append('nombre_documento',this.nombre_documento)
      formData.append('documento',this.documento)

      for (var i = 0; i < this.clientes.length; i++) {
        this.clientes[i].selected ? formData.append('cliente_ids[]',this.clientes[i].id) : null;
      } 

      axios.post('/api/documentos',formData)
      .then(res =>{
        loading.dismiss()
        this.$toast.openToast("Registro de documento exitoso","success")
        this.step = 2
        this.document_images = res.data.data.images;
        this.document_id = res.data.data.document.id;
        console.log(res)
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    },
    async save_signature_areas() {
      
      var loading = await toast.showLoading()

      await loading.present();

      var width = $('#img-pdf').width()
      var height = $('#img-pdf').height()
      
      
      this.signature_areas.forEach((signature,key) => {
        
        var div_width = $('#signature_area-'+key).width()
        var div_height = $('#signature_area-'+key).height()

        signature['height'] = (div_height * 100) / height;
        signature['width'] = (div_width * 100) / width;

      })
     
      const data = {
        sign_documents : this.signature_areas,
        document_id: this.document_id
      }

      axios
      .post('/api/documentos/signature-areas',data)
      .then(res => {
        console.log(res)
        loading.dismiss()
        this.$toast.openToast("Documento registrado exitosamente","success")
        this.$router.push({ name: 'documentos', params : {siniestro_id : this.siniestro_id}})
      }).catch(err => {
        loading.dismiss()
        console.log(err)
      })
    
    },
    edit_name_sign(id){
      document.getElementById(id + "header").onmousedown = null;
      document.getElementById(id).addEventListener("input", inputEvt => {
        console.log(inputEvt)
        console.log("input event fired");
      }, false);
    },
    get_name_sign($event,key){
      this.signature_areas[key].name = $event.target.textContent
    },
    delete_sign(key){
      this.signature_areas.splice(key)
    },
    add_signature(){
      
      this.signature_areas.push({
        name : 'Firma', 
        percentage_top : '1%',
        percentage_left : '1%',
        height : '104px',
        width: '50px',
        num_page : this.current_page,
        user_ids: []
      })

      
      setTimeout(
        self.move('signature_area-'+(self.signature_areas.length-1),self.signature_areas.length-1), 
      1000);
    },
    move(id,key){
      this.current_div = id
      this.current_key = key
      //this.show_clientes = false
      this.dragElement(document.getElementById(id));
      this.show_clientes = true
    },
    
    dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      var self  = this
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        document.getElementById(elmnt.id).onresize = resize();
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }
      
      function resize(e){
        e = e || window.event;
        e.preventDefault();
        //console.log(e)

        var div_width = $('#'+e.target.id).width()
        var div_height = $('#'+e.target.id).height()

        var width = $('#img-pdf').width()
        var height = $('#img-pdf').height()

        self.signature_areas[self.current_key]['height'] = (div_height * 100) / height;
        self.signature_areas[self.current_key]['width'] = (div_width * 100) / width;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement(ev) {
        ev
        var div = document.getElementById(self.current_div);

        var width = $('#img-pdf').width()
        var height = $('#img-pdf').height()


        var div_width = $('#signature_area-'+self.current_key).width()
        var div_height = $('#signature_area-'+self.current_key).height()

        let percentage_top = (parseInt(div.style.top) * 100) / height;
        let percentage_left = (parseInt(div.style.left) * 100) / width;
        
        self.signature_areas[self.current_key]['percentage_top'] = percentage_top;
        self.signature_areas[self.current_key]['percentage_left'] = percentage_left
        self.signature_areas[self.current_key]['height'] = (div_height * 100) / height;
        self.signature_areas[self.current_key]['width'] = (div_width * 100) / width;
        
        if (parseInt(div.style.top) < 0) {
          div.style.top = '0px'
        }

        if (parseInt(div.style.left) < 0) {
          div.style.left = '0px'
        }

        if ((parseInt(div.style.left) + div_width) > width) {
          div.style.left = (width-div_width)+'px'
        }

        if ((parseInt(div.style.top) + div_height) > height) {
          div.style.top = (height-div_height)+'px'
        }

        //$('#'+self.current_div).attr('style','top:'+percentage_top+'%;left:'+percentage_left+'%');

        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  }
});
</script>

<style type="text/css">

  .div-clientes{
    display: block;
    position: fixed;
    width: 100%;
    background: #fff;
    contain: content;
    z-index: 10;
    padding-left: 20px;
  }

 @media (min-width: 320px) and (max-width: 767px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 90% !important;
      transform-origin: right top !important;
    }
    
  }  

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover, .sc-ion-select-popover-h ion-label.sc-ion-select-popover{
      text-align: center;
}
 @media (min-width: 768px) and (max-width: 1500px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 38% !important;
      transform-origin: right top !important;
      text-align: right !important;
    }
    
  } 


ion-radio::part(container){
  display: none;
}
ion-select::part(text){

}

</style>

<style scoped>

  .pencilProfile{
    color: white;
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
    text-align: center;
    margin-left: 6px;
    margin-top: -3px;
  }

  .active-div{
    background-color: blue;
  }

  .draw {
    border: 2px solid;
    border-color: #3c4b64;
    border-radius: 10px;
    padding: 20px; 
    resize: both;
    overflow: auto;
    position: absolute;
    width:  104px;
    height: 50px;
    /*transform: translate(-284px, -175px);*/
  }

  .div-draw {
    position: absolute;
    z-index: 9;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    background-color: rgb(88, 130, 184);
    resize: both;
    overflow: auto;
  }

  .div-draw-header {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: rgb(88, 130, 184);
    color: #fff;
  }

  .div-draw-header:focus-visible {
    outline: none;
  }

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}


@media (min-width: 1000px) {
  
  .img-pdf-convert{
     height: 500px;
     width: 300px;
      border: 2px solid;
  }
} 

@media (min-width: 10px) {
  
  .img-pdf-convert{
     height: 400px;

     width: 200px;
    border: 2px solid;
  }
} 

@media (min-width: 768px) {
 .img-pdf-convert{
     height: 600px;

     width: 480px;
    border: 2px solid;
  }
}

@media (min-width: 992px) {
  .img-pdf-convert{
     height: 1000px;

     width: 780px;
    border: 2px solid;
  }
}

@media (min-width: 1200px) {
  .img-pdf-convert{
     height: 1000px;

     width: 780px;
    border: 2px solid;
  }
}

@media (min-width: 1400px) {
  .img-pdf-convert{
     height: 1400px;

     width: 1200px;
    border: 2px solid;
  }
}

.p-clientes{
  font-weight: bold;
  color: #000;
}

.selected-div-draw{
background-color: rgb(7 66 143);
}

.selected-div-draw .div-draw-header{
background-color: rgb(7 66 143);
}
</style>